import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Coupon } from '@wix/ambassador-loyalty-referral-v1-referral-reward/types';

import { waitForReward, RewardType } from './waitForReward';

export async function waitForCouponReward(flowAPI: PlatformControllerFlowAPI): Promise<Coupon | undefined> {
  const reward = await waitForReward({
    flowAPI,
    rewardType: RewardType.COUPON,
  });

  return reward?.coupon;
}
