import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { queryReferralRewards } from '@wix/ambassador-loyalty-referral-v1-referral-reward/http';
import {
  ReferralReward,
  RewardTypeType as RewardType,
} from '@wix/ambassador-loyalty-referral-v1-referral-reward/types';

import { createBiLogger } from '../../../viewer';
import { FedOpsInteraction } from '../constants';

export { RewardType };

interface Params {
  flowAPI: PlatformControllerFlowAPI;
  rewardType: RewardType;
}

const MIN_RETRY_WAIT_TIME = 1000;
const MAX_RETRY_WAIT_TIME = 2000;
const MAX_WAIT_TIME = 10_000;

const waitFor = (milliseconds: number) => new Promise((resolve) => setTimeout(resolve, milliseconds));

export async function waitForReward({ flowAPI, rewardType }: Params): Promise<ReferralReward | undefined> {
  const { httpClient, bi, fedops } = flowAPI;
  const biLogger = createBiLogger(bi);
  const waitStartTime = Date.now();
  let retryWaitTime = MIN_RETRY_WAIT_TIME;
  let retries = 0;

  fedops.interactionStarted(FedOpsInteraction.LoadReward);

  do {
    try {
      const response = await httpClient.request(
        queryReferralRewards({
          contactId: 'me',
          query: {},
        }),
      );

      const reward = response.data.referralRewards?.find(
        (referralReward) => !!referralReward.rewardedReferredFriendId && referralReward.rewardType === rewardType,
      );

      if (reward) {
        const loadTime = Math.round((Date.now() - waitStartTime) / 1000);

        biLogger.referredFriendRewardLoaded(loadTime);
        fedops.interactionEnded(FedOpsInteraction.LoadReward);

        return reward;
      }
    } catch {}

    if (Date.now() - waitStartTime >= MAX_WAIT_TIME) {
      return;
    }

    await waitFor(retryWaitTime);
    retries++;

    // Exponential backoff
    retryWaitTime = Math.min(2 ** retries * MIN_RETRY_WAIT_TIME, MAX_RETRY_WAIT_TIME);
  } while (true); // eslint-disable-line no-constant-condition
}
