import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { generateReferringCustomerForContact } from '@wix/ambassador-loyalty-referral-v1-referring-customer/http';

import { FedOpsInteraction } from '../constants';

const EDITOR_DEMO_CODE = 'code';

export async function getReferralCode(flowAPI: PlatformControllerFlowAPI): Promise<string> {
  const { httpClient, fedops } = flowAPI;
  const { isViewer } = flowAPI.environment;

  if (!isViewer) {
    return EDITOR_DEMO_CODE;
  }

  fedops.interactionStarted(FedOpsInteraction.LoadReferralCode);

  const response = await httpClient.request(
    generateReferringCustomerForContact({
      contactId: 'me',
    }),
  );

  fedops.interactionEnded(FedOpsInteraction.LoadReferralCode);

  return response.data.referringCustomer?.referralCode!;
}
