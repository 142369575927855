import { runInAction } from 'mobx';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getReferralProgram } from '@wix/ambassador-loyalty-referral-v1-program/http';
import { Type as RewardType, ProgramStatus, ReferralProgram } from '@wix/ambassador-loyalty-referral-v1-program/types';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { getLoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/http';

import { CommonState } from './state';
import { getDemoUserLoyaltyPoints } from './getDemoUserLoyaltyPoints';
import { getDemoReferralProgram } from './getDemoReferralProgram';
import { getDemoLoyaltyProgram } from './getDemoLoyaltyProgram';
import { getUserLoyaltyPoints } from './getUserLoyaltyPoints';

interface Params {
  flowAPI: PlatformControllerFlowAPI;
  state: CommonState;
}

const WARMUP_DATA_KEY = 'loadRemoteStateData';

const loadReferralProgram = async (flowAPI: PlatformControllerFlowAPI) => {
  const { httpClient } = flowAPI;
  const referralProgramResponse = await httpClient.request(getReferralProgram({}));

  return referralProgramResponse.data.referralProgram!;
};

const loadLoyaltyProgram = async (flowAPI: PlatformControllerFlowAPI) => {
  const { httpClient } = flowAPI;
  const referralProgramResponse = await httpClient.request(getLoyaltyProgram({}));

  return referralProgramResponse.data.loyaltyProgram!;
};

export async function loadRemoteStateData({ flowAPI, state }: Params): Promise<void> {
  const { isSSR, isViewer } = flowAPI.environment;
  const { wixCodeApi } = flowAPI.controllerConfig;
  const useWarmupData = isViewer;
  const { warmupData } = wixCodeApi.window;

  let referralProgram: ReferralProgram | undefined;
  let loyaltyProgram: LoyaltyProgram | undefined;
  let userLoyaltyPoints: number | undefined;

  // Reuse backend API responses from SSR on the client-side
  if (useWarmupData && !isSSR) {
    const dataFromSSR = warmupData.get(WARMUP_DATA_KEY);

    if (dataFromSSR) {
      referralProgram = dataFromSSR.referralProgram;
      loyaltyProgram = dataFromSSR.loyaltyProgram;
      userLoyaltyPoints = dataFromSSR.userLoyaltyPoints;
    }
  }

  if (!referralProgram) {
    referralProgram = await loadReferralProgram(flowAPI);

    const useDemoContent = !isViewer && referralProgram.status === ProgramStatus.DRAFT;
    if (useDemoContent) {
      referralProgram = getDemoReferralProgram();
      loyaltyProgram = getDemoLoyaltyProgram();
      userLoyaltyPoints = getDemoUserLoyaltyPoints();
    } else {
      const shouldLoadLoyaltyProgram =
        referralProgram.referredFriendReward?.type === RewardType.LOYALTY_POINTS ||
        referralProgram.referringCustomerReward?.type === RewardType.LOYALTY_POINTS;

      const shouldLoadUserLoyaltyPoints =
        state.isLoggedIn && referralProgram.referredFriendReward?.type === RewardType.LOYALTY_POINTS;

      const result = await Promise.all([
        shouldLoadLoyaltyProgram ? loadLoyaltyProgram(flowAPI) : undefined,
        shouldLoadUserLoyaltyPoints ? getUserLoyaltyPoints(flowAPI) : undefined,
      ]);

      loyaltyProgram = result[0];
      userLoyaltyPoints = result[1];

      // Forward backend API responses from SSR to client-side
      if (useWarmupData && isSSR) {
        warmupData.set(WARMUP_DATA_KEY, {
          referralProgram,
          loyaltyProgram,
          userLoyaltyPoints,
        });
      }
    }
  }

  runInAction(() => {
    state.referralProgram = referralProgram;
    state.loyaltyProgram = loyaltyProgram;
    state.userLoyaltyPoints = userLoyaltyPoints;
  });
}
