import { VisitorLogger } from '@wix/yoshi-flow-editor';
import {
  referralsReferrerClick,
  referralsReferredFriendAction,
  referralsCouponGeneratedAndLoaded,
} from '@wix/bi-logger-referrals-uou/v2';

export function createBiLogger(bi?: VisitorLogger | null) {
  const referringCustomerAction = async (value: string) => {
    await bi?.report(referralsReferrerClick({ value }));
  };

  const referredFriendAction = async (value: string) => {
    await bi?.report(referralsReferredFriendAction({ value }));
  };

  const referredFriendRewardLoaded = async (loadTime: number) => {
    await bi?.report(referralsCouponGeneratedAndLoaded({ loadTime }));
  };

  return {
    referringCustomerAction,
    referredFriendAction,
    referredFriendRewardLoaded,
  };
}
