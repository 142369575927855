import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { LoyaltyPoints } from '@wix/ambassador-loyalty-referral-v1-referral-reward/types';

import { waitForReward, RewardType } from './waitForReward';

export async function waitForLoyaltyPointsReward(
  flowAPI: PlatformControllerFlowAPI,
): Promise<LoyaltyPoints | undefined> {
  const reward = await waitForReward({
    flowAPI,
    rewardType: RewardType.LOYALTY_POINTS,
  });

  return reward?.loyaltyPoints;
}
