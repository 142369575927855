import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { RewardDescriptions, loadRewardDescriptions } from '@wix/referrals-reward-descriptions';

interface Params {
  flowAPI: PlatformControllerFlowAPI;
  customPointsName?: string;
}

const DEFAULT_CURRENCY = 'USD';
const DEFAULT_LOCALE = 'en-US';

export async function initRewardDescriptions({ flowAPI, customPointsName }: Params): Promise<RewardDescriptions> {
  const { httpClient, translations, controllerConfig, getCurrencyFormatter } = flowAPI;
  const { wixCodeApi } = controllerConfig;
  const currency = wixCodeApi.site.currency ?? DEFAULT_CURRENCY;
  const locale = wixCodeApi.site.regionalSettings ?? DEFAULT_LOCALE;

  const formatCurrency = getCurrencyFormatter({
    language: locale,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });

  const formatNumber = (value: number) => {
    return new Intl.NumberFormat(locale).format(value);
  };

  return loadRewardDescriptions({
    i18n: translations.i18n,
    httpClient,
    customPointsName,
    formatNumber,
    formatCurrency(value) {
      return formatCurrency({ value, currency });
    },
  });
}
