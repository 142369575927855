import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { MEMBERS_AREA as MEMBERS_AREA_APP_DEF_ID } from '@wix/app-definition-ids';

import { MY_REWARDS_APP_DEF_ID, MY_REWARDS_WIDGET_ID, MY_REWARDS_SECTION_ID } from './getMyRewardsPageUrl';

export async function navigateToMyRewardsPage(flowAPI: PlatformControllerFlowAPI): Promise<void> {
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { currentUser } = wixCodeApi.user;

  const membersApi = await wixCodeApi.site.getPublicAPI(MEMBERS_AREA_APP_DEF_ID);

  membersApi?.navigateToSection?.({
    appDefinitionId: MY_REWARDS_APP_DEF_ID,
    sectionId: MY_REWARDS_SECTION_ID,
    widgetId: MY_REWARDS_WIDGET_ID,
    memberId: currentUser.id,
  });
}
