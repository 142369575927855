import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { MEMBERS_AREA as MEMBERS_AREA_APP_DEF_ID } from '@wix/app-definition-ids';

export const MY_REWARDS_APP_DEF_ID = '16ed1ac6-01cb-4fb6-a59e-c215cce8fdf6';
export const MY_REWARDS_WIDGET_ID = 'c85a2896-9f7c-4cf1-9bf8-95852cc5219a';
export const MY_REWARDS_SECTION_ID = 'My Rewards';

export async function getMyRewardsPageUrl(flowAPI: PlatformControllerFlowAPI): Promise<string> {
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { currentUser } = wixCodeApi.user;

  const membersApi = await wixCodeApi.site.getPublicAPI(MEMBERS_AREA_APP_DEF_ID);

  try {
    // Will throw if "My Rewards" app is not installed
    const myRewardsUrl = await membersApi?.getSectionUrl({
      appDefinitionId: MY_REWARDS_APP_DEF_ID,
      sectionId: MY_REWARDS_SECTION_ID,
      widgetId: MY_REWARDS_WIDGET_ID,
      memberId: currentUser.id,
    });

    return myRewardsUrl ?? '';
  } catch {}

  return '';
}
