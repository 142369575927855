import { autorun, computed, when } from 'mobx';
import { Type as RewardType } from '@wix/ambassador-loyalty-referral-v1-program/types';

import {
  initCommonState,
  promptLogin,
  isMyRewardsPageInstalled,
  navigateToMyRewardsPage,
  getMyRewardsPageUrl,
  createBiLogger,
} from '../../viewer';
import { ElementId, ViewStateId } from './constants';
import model from './model';
import { defaultState, waitForCouponReward, waitForLoyaltyPointsReward, getCouponRewardCode } from './viewer';

export default model.createController(({ initState, $bind, $w, flowAPI }) => {
  const { state } = initState(defaultState);
  const { errorMonitor, bi } = flowAPI;
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { isViewer } = flowAPI.environment;
  const { t } = flowAPI.translations;
  const biLogger = createBiLogger(bi);
  let pageReadyInitialized = false; // In Editor toggling elements within Elements panel calls pageReady again

  const setReferralCookieFromUrlParam = () => {
    const { isSSR } = flowAPI.environment;
    if (isSSR) {
      return;
    }

    const { location } = wixCodeApi;
    const referralCode = location.path[1];
    if (!referralCode) {
      return;
    }

    const cookieManager = $w(`#${ElementId.CookieManager}`);
    cookieManager.value = referralCode;
  };

  return {
    async pageReady() {
      if (pageReadyInitialized) {
        return;
      }

      pageReadyInitialized = true;

      setReferralCookieFromUrlParam();
      await initCommonState(state, flowAPI);

      const { rewardDescriptions, referralProgram } = state;

      try {
        state.explanationsText = (await rewardDescriptions?.getFriendRewardCondition(referralProgram!)) ?? '';
      } catch (error) {
        errorMonitor?.captureException(error as Error);
        console.error(error);
      }

      const programStates = $w(`#${ElementId.ProgramStates}`);
      const hasMyRewardsPage = await isMyRewardsPageInstalled(flowAPI);
      const myRewardsPageUrl = hasMyRewardsPage ? await getMyRewardsPageUrl(flowAPI) : '';
      const friendRewardType = computed<RewardType>(() => state.referralProgram?.referredFriendReward?.type!);

      autorun(() =>
        programStates.changeState(state.isProgramAvailable ? ElementId.AvailableState : ElementId.NotAvailableState),
      );

      $bind(`#${ElementId.CookieManager}`, {
        onChange(event) {
          state.cookieReferralCode = event.target.value;
        },
      });

      $bind(`#${ElementId.StatusText}`, {
        text: () => t('status.not-available'),
      });

      const title = computed<string>(() => {
        if (state.isTimeoutError) {
          return t('referral-page.timeout.title');
        } else if (friendRewardType.get() === RewardType.LOYALTY_POINTS && state.isLoggedIn && !state.isRewardLoading) {
          const amount = state.userLoyaltyPoints;
          if (!amount) {
            return t('referral-page.title.points.empty');
          }

          const customPointsName = state.loyaltyProgram?.pointDefinition?.customName;
          if (customPointsName) {
            return t('referral-page.title.points.custom', {
              amount,
              pointsName: customPointsName,
            });
          } else {
            return t('referral-page.title.points', { amount });
          }
        } else {
          return rewardDescriptions?.getFriendTitle(referralProgram!) ?? '';
        }
      });

      const hasUsedLoyaltyPointsReward = computed<boolean>(
        () =>
          friendRewardType.get() === RewardType.LOYALTY_POINTS &&
          state.isLoggedIn &&
          !state.userLoyaltyPoints &&
          !state.isRewardLoading,
      );

      const subtitle = computed<string>(() => {
        if (state.isTimeoutError) {
          return t('referral-page.timeout.description');
        } else if (friendRewardType.get() === RewardType.NOTHING) {
          return t('referral-page.subtitle.start-browsing');
        } else if (friendRewardType.get() === RewardType.LOYALTY_POINTS) {
          return t('referral-page.subtitle.redeem-points');
        } else {
          return t('referral-page.subtitle.apply-reward');
        }
      });

      const showGoToHomeButton = computed<boolean>(() => {
        if (state.isTimeoutError) {
          return true;
        } else if (friendRewardType.get() === RewardType.NOTHING) {
          return true;
        } else if (hasUsedLoyaltyPointsReward.get()) {
          return true;
        } else if (friendRewardType.get() === RewardType.LOYALTY_POINTS && state.isLoggedIn && !hasMyRewardsPage) {
          return true;
        }

        return false;
      });

      const isUserInitiallyLoggedIn = state.isLoggedIn;

      when(
        () => state.isLoggedIn && isViewer,
        async () => {
          // Redirect to "My Rewards" page with successful loyalty points reward
          if (!isUserInitiallyLoggedIn && friendRewardType.get() === RewardType.LOYALTY_POINTS) {
            state.isRewardLoading = true;
            const loyaltyPointsReward = await waitForLoyaltyPointsReward(flowAPI);
            if (!loyaltyPointsReward) {
              state.isTimeoutError = true;
              state.isRewardLoading = false;
            } else {
              await navigateToMyRewardsPage(flowAPI);
            }
          } else if (friendRewardType.get() === RewardType.COUPON) {
            state.isRewardLoading = true;
            const couponReward = await waitForCouponReward(flowAPI);
            if (couponReward) {
              state.couponReward = couponReward;
            } else {
              state.isTimeoutError = true;
            }
            state.isRewardLoading = false;
          }
        },
      );

      $bind(`#${ElementId.Content}`, {
        isLoggedIn: () => state.isLoggedIn,
        isLoading: () => state.isRewardLoading,
        isTimeoutError: () => state.isTimeoutError,
        title: () => title.get(),
        subtitle: () => (hasUsedLoyaltyPointsReward.get() ? '' : subtitle.get()),
        explanations: () => (state.isTimeoutError ? '' : state.explanationsText),
        couponCode: () => getCouponRewardCode({ flowAPI, state }),
        rewardType: () => friendRewardType.get(),
        myRewardsPageUrl: () => myRewardsPageUrl,
        rewardButtonLabel: () =>
          showGoToHomeButton.get() ? t('referral-page.go-to-home') : t('referral-page.get-reward'),
        async onRewardButtonClick() {
          if (showGoToHomeButton.get()) {
            biLogger.referredFriendAction('Go to Home');
            wixCodeApi.location.to?.('/');
          } else {
            biLogger.referredFriendAction('Get Reward');
            if (!state.isLoggedIn) {
              state.isLoggedIn = await promptLogin(flowAPI, 'signup');
            } else if (friendRewardType.get() === RewardType.LOYALTY_POINTS) {
              await navigateToMyRewardsPage(flowAPI);
            }
          }
        },
      });
    },
    updateWidgetViewState(viewStateId: ViewStateId) {
      state.isLoggedIn = viewStateId !== ViewStateId.LoggedOut;
    },
    exports: {},
  };
});
